<template>
  <div>
    <PageTitlebar title="Add New Advertiser Account">
      <template v-slot:top>
        <Link
          :to="{ name: 'AdvertiserAccountsOverview' }"
          label="Back to Accounts"
          icon="sym_r_chevron_left"
          class="q-mb-sm"
        />
      </template>
    </PageTitlebar>

    <AdvertiserAccountForm
      type="create"
      :submitting="submitting"
      @submit="createAdvertiserAccount"
    />
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import Link from "@/components/UI/Link";
import AdvertiserAccountForm from "@/components/Advertiser/AdvertiserAccountForm";

export default {
  name: "AdvertiserAccountCreator",
  components: {
    PageTitlebar,
    Link,
    AdvertiserAccountForm
  },
  data() {
    return {
      submitting: false
    };
  },
  mounted() {},
  methods: {
    createAdvertiserAccount(formData) {
      this.submitting = true;

      this.$store
        .dispatch("advertiserAccounts/POST_REQUEST", {
          endpoint: "create",
          params: {
            name: formData.name,
            product_type_id: formData.productTypeId,
            // bid_method: formData.bidMethod,
            static_bid_value: formData.staticBidValue,
            daily_budget: formData.dailyBudgetValue,
            country_code: formData.countryCode
          }
        })
        .then(res => {
          if (!res.id) {
            this.triggerActionOutcomeAlert("create", false);
          } else {
            this.triggerActionOutcomeAlert("create", true);
            this.submitting = false;

            // Store the account info (name & id) in sessionStorage, and utilize that on success popup that gets shown on Advertiser Accounts Overview
            sessionStorage.setItem(
              "jf-LastAdvertiserAccountCreatorSuccess",
              JSON.stringify({
                id: res.id,
                name: formData.name
              })
            );

            this.$router.push({ name: "AdvertiserAccountsOverview" });
          }
        })
        .catch(e => {
          console.log(e);
          if (e.response) {
            this.triggerActionOutcomeAlert(
              "create",
              false,
              e.response.data.errors[0].detail
            );
          } else {
            this.triggerActionOutcomeAlert("create", false);
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
